const logo = document.querySelector(".logoRef");
const resumeDownloadBtn = document.getElementById("resumeDownloadBtn");
const contactBtnId = document.getElementById("contactBtnId");
const newContactBtnId = document.getElementById("alternativeContactBtnId");
logo.addEventListener("click", () => {
  window.location.href = window.location.href; // Reload the page
});

const navLinks = document.querySelectorAll(".navItemLinkHref");

window.addEventListener("scroll", () => {
  const currentScroll = window.scrollY;

  document.querySelectorAll("section").forEach((section) => {
    const sectionTop = section.offsetTop;
    const sectionHeight = section.clientHeight;

    if (
      currentScroll >= sectionTop - 200 &&
      currentScroll <= sectionTop + sectionHeight
    ) {
      const targetLinkId = section.getAttribute("id");

      navLinks.forEach((link) => {
        if (link.getAttribute("href") === `#${targetLinkId}`) {
          link.classList.add("navListItemActive");
        } else link.classList.remove("navListItemActive");
      });
    }
  });
});

contactBtnId.addEventListener("click", function () {
  const contactSection = document.getElementById("contact");
  if (contactSection) {
    contactSection.scrollIntoView({ behavior: "smooth" });
  }
});

newContactBtnId.addEventListener("click", function () {
  const contactSection = document.getElementById("contact");
  if (contactSection) {
    contactSection.scrollIntoView({ behavior: "smooth" });
  }
});

// Add a click event listener to the button
resumeDownloadBtn.addEventListener("click", function () {
  // Replace 'resume.pdf' with the correct path to your PDF file
  var resumePdfUrl = require("../assets/pdf/resume.pdf");

  // Trigger the download by creating a hidden anchor element
  var link = document.createElement("a");
  link.href = resumePdfUrl;
  link.download = "Resume_Nabendu_Paul_2023.pdf";
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
});
